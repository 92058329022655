import { is, isNil, isEmpty, mapObjIndexed, values } from 'ramda';
import { camelize } from 'utils/keysConverter';
import FailResponseError from 'utils/responseErrors/FailResponseError';
import ConflictResponseError from 'utils/responseErrors/ConflictResponseError';
import ForbiddenResponseError from 'utils/responseErrors/ForbiddenResponseError';
import NotFoundResponseError from 'utils/responseErrors/NotFoundResponseError';
import BadRequestResponseError from 'utils/responseErrors/BadRequestResponseError';
import { reloadPage } from 'utils/LocationHelpers';

export function authenticityToken() {
  const token = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
}

function headers() {
  return {
    Accept: '*/*',
    'content-Type': 'application/json',
    'X-CSRF-Token': authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
}

function headersMultipartFormData() {
  return {
    Accept: '*/*',
    'X-CSRF-Token': authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
}

/**
 * @param {string} key
 * @param {unknown} value
 * @param {string?} lastKey
 */
function toFormDataQuery(key, value, lastKey = null) {
  if (Array.isArray(value)) {
    if (isEmpty(value)) {
      return { key: `${key}`, value: [] };
    }
    const flattenArray = value.flatMap((arrayValue, i) => {
      const arKey = is(Object, arrayValue) && lastKey.includes('_attributes') ? `${key}[${i}]` : `${key}[]`;
      return toFormDataQuery(arKey, arrayValue, lastKey);
    });
    return flattenArray;
  }

  if ((!is(Object, value) && !isNil(value)) || value instanceof File) {
    return { key, value };
  }

  return values(
    mapObjIndexed((nestedValue, nestedKey) => toFormDataQuery(`${key}[${nestedKey}]`, nestedValue, nestedKey), value),
  );
}

const NOT_JSON_RESPONSE_STATUSES = [204, 202, 403];

export default {
  checkSuccess(response) {
    if (response.status === 401) {
      reloadPage();
    }
    if (response.status === 403) {
      throw new ForbiddenResponseError(response);
    }
    if (response.status === 404) {
      throw new NotFoundResponseError(response);
    }
    if (response.status === 409) {
      return response.json().then(json => {
        throw new ConflictResponseError(response, json);
      });
    }
    if (response.status >= 400 && response.status < 500) {
      return response.json().then(json => {
        throw new FailResponseError(response, json.errors, json.detail);
      });
    }
    if (response.status >= 500) {
      throw new BadRequestResponseError(response);
    }

    return response;
  },

  toJSON(response) {
    if (NOT_JSON_RESPONSE_STATUSES.includes(response.status)) {
      return response;
    }

    return response.json();
  },

  toFormData(model, attributes) {
    const formData = new FormData();
    const convertedParams = toFormDataQuery(model, attributes);
    convertedParams.flat(10).forEach(({ key, value }) => {
      formData.append(key, value);
    });

    return formData;
  },

  // use multipart/form-data when there are any file inputs
  postMultipartFormData(url, formData) {
    return fetch(url, {
      method: 'post',
      headers: headersMultipartFormData(),
      credentials: 'same-origin',
      body: formData,
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  post(url, json) {
    const params = {};
    if (json) {
      Object.assign(params, json);
    }

    return fetch(url, {
      method: 'post',
      headers: headers(),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  get(url, optionalHeaders = {}) {
    return fetch(url, {
      method: 'get',
      headers: { ...headers(), ...optionalHeaders },
      credentials: 'same-origin',
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  getNoCache(url) {
    const noCacheHeader = { 'Cache-Control': 'no-cache' };
    return this.get(url, noCacheHeader);
  },

  getBinary(url) {
    return fetch(url, {
      method: 'get',
      headers: headers(),
      credentials: 'same-origin',
    }).then(this.checkSuccess);
  },

  put(url, json) {
    const params = {};
    if (json) {
      Object.assign(params, json);
    }

    return fetch(url, {
      method: 'put',
      headers: headers(),
      body: JSON.stringify(params),
      credentials: 'same-origin',
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  patch(url, params) {
    return fetch(url, {
      method: 'PATCH',
      headers: headers(),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  // use multipart/form-data when there are any file inputs
  patchMultipartFormdata(url, formData) {
    return fetch(url, {
      method: 'PATCH',
      headers: headersMultipartFormData(),
      credentials: 'same-origin',
      body: formData,
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },

  delete(url, params = {}) {
    return fetch(url, {
      method: 'delete',
      headers: headers(),
      credentials: 'same-origin',
      body: JSON.stringify(params),
    })
      .then(this.checkSuccess)
      .then(this.toJSON)
      .then(camelize);
  },
};
