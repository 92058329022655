import useFeatureFlags from 'hooks/useFeatureFlags';
import useStyles from './useStyles';

const GlobalStyles = ({ children }: { children?: JSX.Element }) => {
  const enableTopNavigation = useFeatureFlags('ff_enable_top_navigation');

  useStyles({ enableTopNavigation }); // inject global styles
  return children ?? null;
};

export default GlobalStyles;
