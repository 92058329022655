import { makeStyles } from '@material-ui/core/styles';

interface Props {
  enableTopNavigation: boolean;
}

export default makeStyles(theme => ({
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
      fontFamily: theme.typography.fontFamily,
      // with no user overrides, browsers set the default font size to 16px. Users can grow or
      // shrink that value, and we want 1rem to scale with that. Using a percentage sets 1rem to
      // 14px normally, and proportionally more or less if the default font size is scaled.
      fontSize: `${(theme.typography.htmlFontSize / 16) * 100}%`,
      fontWeight: theme.typography.fontWeightRegular,
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: (props: Props) => ({
      // on mobile, the viewport area can change; 100svh represents the smallest possible viewport height
      minHeight: '100svh',
      fallbacks: {
        minHeight: '100vh', // https://cssinjs.org/jss-syntax#fallbacks
      },
      display: 'flex', // allow content to stretch to fill the full screen height with `flex: 1`
      flexDirection: 'column',

      padding: 0,
      margin: 0,
      backgroundColor: props.enableTopNavigation ? theme.palette.white : theme.palette.background.default,
      '@media print': {
        backgroundColor: theme.palette.white,
      },
    }),
    '#main': {
      flex: 1, // grow to screen height if content isn't already that tall
      display: 'flex', // allow content to grow to screen height with `flex: 1`
      flexDirection: 'column',
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    'input, textarea, button': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    '.highcharts-menu': {
      border: `1px solid ${theme.palette.border} !important`,
      padding: '12px 0 14px !important',
      '& hr': {
        backgroundColor: `${theme.palette.border} !important`,
        border: 'none',
        height: 1,
        margin: '10px 0 !important',
      },
      '& .highcharts-menu-item': {
        color: `${theme.palette.text.link} !important`,
        fontSize: `${theme.typography.pxToRem(14)} !important`,
        padding: '7px 33px 7px 23px !important',
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    '.scrollspy-anchor': {
      marginTop: -50,
      paddingTop: 50,
    },

    '.twitter-button': {
      backgroundColor: `${theme.palette.social.twitter} !important`,
      color: `${theme.palette.text.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.lighten(theme.palette.social.twitter, 0.05)} !important`,
        color: `${theme.palette.text.white} !important`,
      },
    },

    '.google-button': {
      backgroundColor: `${theme.palette.social.google} !important`,
      color: `${theme.palette.text.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.lighten(theme.palette.social.google, 0.05)} !important`,
        color: `${theme.palette.text.white} !important`,
      },
    },

    '.facebook-button': {
      backgroundColor: `${theme.palette.social.facebook} !important`,
      color: `${theme.palette.text.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.lighten(theme.palette.social.facebook, 0.05)} !important`,
        color: `${theme.palette.text.white} !important`,
      },
    },

    '.lp-screen-reader-only': {
      // these settings hide content from view while not hiding it from screen readers
      // if you want to reveal that content on focus/hover, you'll need to unset or override these fields
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 1,
    },
    '.lp-skip-link': {
      // new stacking context
      position: 'absolute',
      zIndex: 1,
      // out of view by default
      transform: 'translate(-50%, -100%)',
      opacity: 0,
      // centered and visible
      left: '50%',
      color: theme.palette.text.primary,
      backgroundColor: theme.fade(theme.palette.page, 0.9),
      fontWeight: '700',
      padding: '8px',
      // appear smoothly
      transition: 'transform 150ms ease-in, opacity 150ms ease-in',
      '&:focus': {
        transform: 'translate(-50%, 0%)',
        opacity: 1,
        transitionTimingFunction: 'ease-out',
      },
      '@media (prefers-reduced-motion: reduce)': {
        // no motion, fade only
        transition: 'opacity 150ms ease-in',
      },
    },
  },
}));
