import React, { PureComponent } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';
import Icon from 'components/Icon';
import Rotate from 'components/Rotate';
import styles from './styles';

class Button extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.string,
    iconAfter: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    size: PropTypes.oneOf(['default', 'thin', 'xsmall']),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    variant: PropTypes.oneOf([
      'default',
      'link',
      'filter',
      'filterActive',
      'select',
      'social',
      'header',
      'visibilityToggle',
      'visibilityToggleHidden',
      'text',
      'submit',
      'contained',
      'outlined',
    ]),
    color: PropTypes.oneOf([
      'default',
      'themized',
      'twitter',
      'facebook',
      'inherit',
      'transparent',
      'inverse',
      'selectable',
      'selected',
      'bordered',
      'bordered_without_hover_focus',
    ]),
    iconPosition: PropTypes.oneOf(['default', 'auto']),
    fullWidth: PropTypes.bool,
    variableWidth: PropTypes.bool,
    square: PropTypes.bool,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    rotate: PropTypes.bool,
    activeClassName: PropTypes.string,
    href: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    role: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  };

  static defaultProps = {
    icon: null,
    iconAfter: null,
    children: null,
    disabled: false,
    fullWidth: false,
    variableWidth: false,
    square: false,
    color: 'default',
    iconPosition: 'default',
    variant: 'contained',
    size: 'default',
    align: 'center',
    className: null,
    onClick: Function.prototype,
    iconWidth: null,
    iconHeight: null,
    rotate: false,
    activeClassName: null,
    href: null,
    to: null,
    role: '',
    target: '_self',
    rel: '',
  };

  render() {
    const {
      classes,
      className,
      children,
      disabled,
      icon,
      iconAfter,
      variableWidth,
      fullWidth,
      size,
      variant,
      color,
      iconPosition,
      square,
      align,
      iconWidth,
      iconHeight,
      rotate,
      activeClassName,
      role,
      ...rest
    } = this.props;

    let element = 'button';
    let buttonRole = 'button';
    if (this.props.href || variant === 'link') {
      element = 'a';
      buttonRole = role || 'link';
    }
    if (this.props.to) {
      element = Link;
      if (activeClassName) {
        element = NavLink;
      }
      buttonRole = role || 'link';
    }

    const buttonProps = {
      component: element,
      role: buttonRole,
      disabled,
      variant: ['contained', 'outlined', 'text'].includes(variant) ? variant : undefined,
      className: cn([classes[align]], {
        [classes.root]: true,
        [classes.disabled]: disabled,
        [classes.variableWidth]: variableWidth,
        [classes.fullWidth]: fullWidth,
        [classes.inherit]: color === 'inherit',
        [classes.twitter]: color === 'twitter',
        [classes.facebook]: color === 'facebook',
        [classes.themized]: color === 'themized',
        [classes.transparent]: color === 'transparent',
        [classes.inverse]: color === 'inverse',
        [classes.selectable]: color === 'selectable',
        [classes.selected]: color === 'selected',
        [classes.bordered]: color === 'bordered',
        [classes.bordered_without_hover_focus]: color === 'bordered_without_hover_focus',
        [classes.link]: variant === 'link' || variant === 'text',
        [classes.social]: variant === 'social',
        [classes.filter]: variant === 'filter',
        [classes.select]: variant === 'select',
        [classes.header]: variant === 'header',
        [classes.visibilityToggle]: variant === 'visibilityToggle',
        [classes.visibilityToggleHidden]: variant === 'visibilityToggleHidden',
        [classes.thin]: size === 'thin',
        [classes.xsmall]: size === 'xsmall',
        [classes.square]: square,
        [classes.filterActive]: variant === 'filterActive',
        [className]: !!className,
      }),
      ...rest,
    };

    const iconContainerProps = {
      className: cn(classes.iconContainer, {
        [classes.iconAutoLeft]: iconAfter && iconPosition === 'auto',
        [classes.iconAutoRight]: icon && iconPosition === 'auto',
      }),
    };

    return (
      <MUIButton {...buttonProps}>
        {icon && (
          <span {...iconContainerProps}>
            <Rotate noRotate={!rotate}>
              <Icon icon={icon} width={iconWidth} height={iconHeight} />
            </Rotate>
          </span>
        )}
        {children && <span className={classes.content}>{children}</span>}
        {iconAfter && (
          <span {...iconContainerProps}>
            <Icon icon={iconAfter} width={iconWidth} height={iconHeight} />
          </span>
        )}
      </MUIButton>
    );
  }
}

export default withStyles(styles)(Button);
