import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import Button from '../Button';

import useStyles from './useStyles';

const VisibilityToggle = ({ onClick, visible, activeText, hiddenText }) => {
  const classes = useStyles();
  return (
    <Button
      variableWidth
      onClick={onClick}
      variant={visible ? 'visibilityToggle' : 'visibilityToggleHidden'}
      icon={visible ? 'visibility' : 'visibilityOff'}
    >
      {visible ? (
        <Typography className={classes.text}>{activeText}</Typography>
      ) : (
        <Typography className={classes.text}>{hiddenText}</Typography>
      )}
    </Button>
  );
};

VisibilityToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  activeText: PropTypes.string.isRequired,
  hiddenText: PropTypes.string.isRequired,
};

VisibilityToggle.defaultProps = {
  visible: true,
};

export default VisibilityToggle;
