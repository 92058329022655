import queryString from 'query-string';
import { isNil } from 'ramda';
import { isBlank } from 'utils/Utils';

export default {
  adminBasePath: () => '/new/admin',

  adminDataSourcesPath: () => '/data_sources',

  adminOrganizationsPath: () => '/organizations',
  adminEditOrganizationPath: id => `/organizations/${id}/edit`,
  adminNewOrganizationPath: params => {
    const newOrganizationPath = '/organizations/new';
    return isNil(params) ? newOrganizationPath : `${newOrganizationPath}?${queryString.stringify(params)}`;
  },
  adminOrganizationUploadsPath: id => `/organizations/${id}/uploads`,
  adminRcesPath: () => `/rces`,
  adminOrganizationGenerateOneMetaPath: id => `/organizations/${id}/generate_one_meta`,
  adminFailingRostersPath: () => '/failing_rosters',
  adminSubscriptionsPath: () => '/subscriptions',
  adminNewSubscriptionPath: () => '/subscriptions/new',
  adminEditSubscriptionPath: id => `/subscriptions/${id}/edit`,

  adminGroupsPath: () => '/groups',
  adminEditGroupPath: id => `/groups/${id}/edit`,
  adminProvidersPath: () => '/providers',
  adminTrialsPath: () => '/trials',
  adminEmailsPath: () => '/emails',
  adminEmailPath: id => `/emails/${id}`,
  adminAuditsPath: () => '/audits',
  adminAuditPath: id => `/audits/${id}`,
  adminDataSourceDetailPath: dataSource => `/data_sources/${dataSource}`,
  adminDataSourceJamfPath: () => '/data_sources/jamf',
  adminDataSourceSdpcPath: () => '/data_sources/sdpc',
  adminMapperPath: (uuid, params) => `/mapper/${uuid}?${queryString.stringify(params)}`,
  adminOrganizationMapperPath: id => `/organization_mapper/${id}`,
  adminEditOrganizationsPath: id => `/admin/organizations/${id}/edit`,

  adminDiscountPath: () => '/discount_codes',
  adminEditDiscountPath: id => `/discount_codes/${id}/edit`,
  adminNewDiscountPath: () => `/discount_codes/new`,

  adminProductsPath: () => '/products',
  adminEditProductPath: id => `/products/${id}/edit`,
  adminNewProductPath: () => `/products/new`,

  adminTagsPath: () => '/tags',
  adminEditTagPath: id => `/tags/${id}/edit`,
  adminNewTagPath: () => `/tags/new`,

  adminTagGroupsPath: () => '/tag_groups',
  adminEditTagGroupPath: id => `/tag_groups/${id}/edit`,
  adminNewTagGroupPath: () => `/tag_groups/new`,

  adminTagFiltersPath: () => '/tag_filters',
  adminEditTagFilterPath: id => `/tag_filters/${id}/edit`,
  adminNewTagFilterPath: () => `/tag_filters/new`,

  adminFormTemplatesPath: () => '/form_templates',
  adminEditFormTemplatePath: id => `/form_templates/${id}/edit`,
  adminNewFormTemplatePath: () => `/form_templates/new`,

  adminCompaniesPath: () => '/companies',
  adminEditCompanyPath: id => `/companies/${id}/edit`,
  adminNewCompanyPath: () => `/companies/new`,

  adminUsersPath: () => '/users',
  adminEditUserPath: id => `/users/${id}/edit`,
  adminNewUserPath: () => `/users/new`,

  adminDataSourceSdpcOrganizationsPath: () => '/data_sources/sdpc_orgs',

  adminDataSourcesProductRequestsPath: () => '/data_sources/product_requests',

  adminDataSourcesTrustedAppsPath: () => '/data_sources/trusted_apps',

  adminDataSourcesProductRequestsNewToolPath: (requestId, params) => {
    const path = `/data_sources/product_requests/${requestId}/tools/new`;
    if (isBlank(params)) return path;

    return `${path}?${queryString.stringify(params)}`;
  },

  adminEacDataPath: () => '/data_sources/maintenance_hub/eac_data',
  adminMarketplacePath: () => '/data_sources/maintenance_hub/marketplace',

  adminMaintenancePath: () => '/data_sources/maintenance_hub/tools',
  adminMaintenanceToolPath: toolId => `/data_sources/maintenance_hub/tool/${toolId}`,
};
